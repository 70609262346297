<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo mb-3">Histórico Financeiro</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 
            <template v-slot:top>
              <BrToolBar @search="buscar" :configFilter="{ listaSelect: itemSelectKeys, getItens: getItens, jsonData: false }" :addlFilter="true">
                <template v-slot:select>
                  <v-col cols="6" sm="4" md="5">
                    <v-select
                      :items="itemsTypeLancamentos"
                      label="Tipo de lançamento"
                      hide-details
                      v-model="itemTypeLancamentoSelected" />
                  </v-col>
                </template>
              </BrToolBar>
            </template>

            <!-- <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.value | currency }}</td>
                  <td>{{ item.entityName }}</td>
                  <td>{{ item.transaction }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.date }}</td>
                  <td class="text-end">
                    <v-btn
                      v-if="permiteAcao($route, 'edit')"
                      small
                      @click="editItem(item)"
                      tile
                      outlined 
                      :color="variables.colorPrimary"
                      class="mr-2"
                    >
                      Editar
                    </v-btn>

                    <v-btn
                      v-if="permiteAcao($route, 'delete')"
                      small
                      @click="deleteItem(item)"
                      tile
                      outlined 
                      :color="variables.colorError"
                    >
                      Excluir
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template> -->

            <template v-slot:item.value="{ item }">
              {{ item.value | currency }}
            </template>

            <!-- <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'edit')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2">
                Editar
              </v-btn>

              <v-btn
                v-if="permiteAcao($route, 'delete')"
                small
                @click="deleteItem(item)"
                tile
                outlined 
                :color="variables.colorError">
                Excluir
              </v-btn> 
            </template> -->

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar } from '@/core/service/utils'
import Events from '@/core/service/events'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'
import moment from 'moment'

const namespaceStore = 'entries'

export default {
  name: 'Entries',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'date' },
      { align: 'start', class: 'table-header', text: 'Transação', value: 'transaction' },
      { align: 'start', class: 'table-header', text: 'Entidade', value: 'entityName' },
      { align: 'start', class: 'table-header', text: 'Descrição', value: 'description' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],

    itemsTypeLancamentos: [
      { text: 'Todos', value: null },
      { text: 'Débito', value: 'D' },
      { text: 'Crédito', value: 'C' }
    ],
    itemTypeLancamentoSelected: null,
  }),

  watch: {
    itemTypeLancamentoSelected (val) {
      this.setFilterTypeEntry(val)
      this.loadingPag = true
      this.getItens({})
        .finally(() => this.loadingPag = false)
    }
  },

  mounted () {
    Events.$on('paginacao::clear', () => { 
      this.itemTypeLancamentoSelected = null
    })
  },


  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'item']),
    itemSelectKeys () {
      return  {
        created: '',
        id: '',
        entityId: '',
        transaction: '',
        entryTypeId: '',
        value: '',
        Entity: {
          name,
        },
        entryType: {
          description: ''
        }
      }
    }
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens', 'setFilterTypeEntry']),
    ...mapActions('filter', ['setObjFilter']),
    init() {
      const objFilter = {
        page: 1,
        pageSize: this.$store.getters.ItensPaginacao,
        filterDtStart: moment().format('YYYY-MM-DD 00:00:00'),
        filterDtEnd: moment().format('YYYY-MM-DD 23:59:59')
      }
      this.setObjFilter(objFilter)
      this.getItens(objFilter)
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.loadingTable = false)
    },

    buscar(val) {
      this.loadingPag = true
      this.objCampoBusca['_addlFilter'] = { "Entity:jsonData->>'name'_ilike":`%${val}%` }
      this
        .getItens({ _addlFilter: { "Entity:jsonData->>'name'_ilike":`%${val}%` } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
